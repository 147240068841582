/** 
 * VALIDATION_PATTERNS
 * 
 * @version 0.1 (2021.05.19 : usayama)
*/
export const VALIDATION_PATTERNS = {
	'email' : /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i,
	'password' : /^[a-zA-Z0-9!-/:-@¥[-`{-~]{8,255}$/i,
	'zip' : /^\d{7}$/,
	'zip_hyphen' : /^[0-9]{3}-[0-9]{4}$/,
	'tel' : /^(0{1}\d{9,10})$/,
	'tel_hyphen' : /\d{2,4}-\d{2,4}-\d{4}/,
	'strings' : /^[a-zA-Z][a-zA-Z0-9_-]{1,255}$/i,
};

